export const FETCH_ALL = "FETCH_ALL";
export const FETCH_ALL_ITEMS = "FETCH_ALL_ITEMS";
export const FETCH_ALL_GUIDES = "FETCH_ALL_GUIDES";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const FETCH_ONE = "FETCH_ONE";
export const FETCH_ONE_GUIDE = "FETCH_ONE_GUIDE";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const DELETE = "DELETE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_USER = "GET_USER";
